<template>
  <div
    ref="customSlides"
    class="custom-slider"
    :style="{
      paddingLeft: paddingsForSlider,
      paddingRight: paddingsForSlider,
      overflowX: offsetStartPosition ? 'hidden': 'auto',
      gap: `${gap}px`,
    }"
    @scrollend="scrollEnd"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      class="m-btn custom-slide"
    >
      <slot
        name="item"
        :slide="item"
        :index="index"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import throttle from 'lodash/throttle';

const OFFSET_FOR_SLIDE = 70;

function getScrollbarWidth() {
  // Creating invisible container
  const outer = document.createElement('div');
  outer.style.visibility = 'hidden';
  outer.style.overflow = 'scroll'; // forcing scrollbar to appear
  outer.style.msOverflowStyle = 'scrollbar'; // needed for WinJS apps
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement('div');
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

  // Removing temporary elements from the DOM
  outer.parentNode.removeChild(outer);

  return scrollbarWidth;
}

export default {
  name: 'CustomScrollSlider',
  props: {
    items: {
      type: Array,
      required: true,
    },
    containerWidth: {
      type: Number,
      default: 1240,
    },
    needPadding: {
      type: Boolean,
      default: true,
    },
    gap: {
      type: [Number, String],
      default: 0,
    },
    byStep: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    paddingsForSlider: 0,
    stepForScroll: 0,

    offsetStartPosition: 0,
    onSlideStartPosition: 0,
    startEmit: false,

    slideNumber: 1,
  }),
  computed: {
    ...mapGetters({
      windowSize: 'getWindowSize',
      isTablet: 'isTablet',
    }),
  },
  watch: {
    isTablet: {
      immediate: true,
      handler() {
        if (process.browser) {
          if (this.isTablet) {
            this.removeEventListeners();
          } else {
            document.addEventListener('mousedown', this.setStartPosition);
            document.addEventListener('touchstart', this.setStartPosition);
            document.addEventListener('mousemove', this.sliderMouseMove);
            document.addEventListener('touchmove', this.sliderMouseMove);
            document.addEventListener('mouseup', this.sliderMouseUp);
            document.addEventListener('touchend', this.sliderMouseUp);
          }
        }
      },
    },
    windowSize() {
      this.onWindowResized();
    },
    slideNumber: {
      immediate: true,
      handler() {
        this.$emit('updateSlideNumber', this.slideNumber);
      },
    },
  },
  mounted() {
    this.onWindowResized();
  },
  beforeDestroy() {
    this.removeEventListeners();
  },
  methods: {
    removeEventListeners() {
      document.removeEventListener('mousedown', this.setStartPosition);
      document.removeEventListener('touchstart', this.setStartPosition);
      document.removeEventListener('mousemove', this.sliderMouseMove);
      document.removeEventListener('touchmove', this.sliderMouseMove);
      document.removeEventListener('mouseup', this.sliderMouseUp);
      document.removeEventListener('touchend', this.sliderMouseUp);
    },

    onWindowResized() {
      this.$refs.customSlides.scrollLeft = 0;
      this.slideNumber = 1;
      this.getContainerMarginLeft();
      setTimeout(() => {
        this.getStepForScroll();
      });
    },
    getContainerMarginLeft() {
      if (this.needPadding) {
        const container = Math.min(this.containerWidth || this.windowSize);
        const padding = Math.max((this.windowSize - container - getScrollbarWidth()) / 2, 8);
        this.paddingsForSlider = `${padding}px`;
      } else {
        this.paddingsForSlider = '0px';
      }
    },
    getStepForScroll() {
      const sliderGap = this.gap || getComputedStyle(this.$refs.customSlides)?.gap || 0;
      const slideWidth = this.$refs.customSlides.querySelector('.custom-slide')?.offsetWidth || 0;
      this.stepForScroll = slideWidth + parseInt(sliderGap, 10);
    },

    nextSlide(steps = 1) {
      this.smoothScroll();
      this.$refs.customSlides.scrollLeft += this.stepForScroll * steps;
      this.slideNumber = Math.min(this.slideNumber + steps, 9);
    },
    prevSlide(steps = 1) {
      this.smoothScroll();
      // в мобильном когда слайд на половину обрезан слева, сначала выравнивает, потом листает по 1 слайду
      const remains = Math.floor(this.$refs.customSlides.scrollLeft % this.stepForScroll);
      if (remains) {
        // eslint-disable-next-line max-len,vue/max-len
        this.$refs.customSlides.scrollLeft -= remains + this.stepForScroll * (steps - 1);
      } else {
        this.$refs.customSlides.scrollLeft -= this.stepForScroll * steps;
      }

      this.slideNumber = Math.max(this.slideNumber - steps, 1);
    },
    smoothScroll() {
      this.$refs.customSlides.style.scrollBehavior = 'smooth';
      setTimeout(() => {
        this.$refs.customSlides.style.scrollBehavior = 'initial';
      }, 500);
    },

    setStartPosition(e) {
      if (e.target.closest('.custom-slider') === this.$refs.customSlides) {
        const event = e.clientX ? e : e.changedTouches?.[0] || {};
        this.onSlideStartPosition = event.clientX || 0;
        this.offsetStartPosition = event.clientX || 0;
      }
    },

    sliderMouseMove(e) {
      if (!this.offsetStartPosition) return;

      const event = e.clientX ? e : e.changedTouches?.[0] || {};
      const offsetEndPosition = event.clientX || 0;

      // пользователь не случайно сдвинул слайдер, а реально скроллил
      if (
        (this.onSlideStartPosition - OFFSET_FOR_SLIDE) > offsetEndPosition
        || (this.onSlideStartPosition + OFFSET_FOR_SLIDE) < offsetEndPosition
      ) {
        this.slideStartEmit();
      }

      this.$refs.customSlides.scrollLeft += this.offsetStartPosition - offsetEndPosition;

      this.offsetStartPosition = event.clientX || 0;
    },

    sliderMouseUp(e) {
      if (!this.offsetStartPosition) return;

      const event = e.clientX ? e : e.changedTouches?.[0] || {};
      const offsetEndPosition = event.clientX || 0;

      if (this.byStep) {
        this.slideStartEmit();
        if ((this.offsetStartPosition - OFFSET_FOR_SLIDE) > offsetEndPosition) {
          this.nextSlide();
        } else if ((this.offsetStartPosition + OFFSET_FOR_SLIDE) < offsetEndPosition) {
          this.prevSlide();
        }
      }

      this.offsetStartPosition = 0;
    },

    scrollEnd() {
      const { scrollLeft } = this.$refs.customSlides;
      this.slideNumber = Math.round(scrollLeft / this.stepForScroll) + 1;
      this.slideEndEmit();
    },

    slideStartEmit() {
      if (!this.startEmit) {
        this.$emit('slideStart');
      }
    },
    // eslint-disable-next-line func-names
    slideEndEmit: throttle(function () {
      this.$emit('slideEnd');
      this.startEmit = false;
    }, 100),
  },
};
</script>

<style scoped lang="scss">

.custom-slider {
  display: flex;

  cursor: grab;
  scroll-behavior: initial;

  @include media-down(tablet) {
    scroll-behavior: smooth;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .custom-slide {
    user-select: none;
    cursor: grab;

    ::v-deep img {
      pointer-events: none;
    }
  }
}

</style>
