<template>
  <div class="parents-videos">
    <img
      id="parents-videos--bg-image"
      loading="lazy"
      src="/v2/main/parents-videos/moon-and-stars.webp"
      alt="image"
      class="parents-videos--bg-image"
    >

    <div
      ref="parentsVideosHead"
      class="parents-videos__head"
    >
      <h2 class="title-primary">
        Нас выбирают родители и&nbsp;дети <span>со&nbsp;всего мира</span>
        <img
          id="parents-videos__head_earth"
          loading="lazy"
          class="parents-videos__head_earth"
          src="/v2/main/parents-videos/earth.webp"
          alt="earth"
        >
      </h2>

      <div class="parents-videos__controls">
        <button
          class="m-btn"
          @click="prevSlide"
        >
          <img
            loading="lazy"
            src="/v2/main/arrow-left.svg"
            alt="prev"
          >
        </button>
        <button
          class="m-btn"
          @click="nextSlide"
        >
          <img
            loading="lazy"
            src="/v2/main/arrow-right.svg"
            alt="next"
          >
        </button>
      </div>
    </div>

    <custom-scroll-slider
      ref="slider"
      class="parents-videos__slides"
      :items="items"
      :container-width="1240"
      :gap="isTablet ? 22 : isLaptop ? 36 : 50"
    >
      <template #item="{ slide }">
        <div
          class="parents-videos__slide"
          :style="{ color: slide.color }"
        >
          <div class="parents-videos__slide_image">
            <img
              loading="lazy"
              :src="`/v2/main/parents-videos/${slide.image}`"
              :alt="slide.name"
            >
          </div>

          <h4>{{ slide.name }}</h4>
          <h5>{{ slide.description }}</h5>
          <p
            class="text-primary"
            v-html="slide.text"
          />
          <button
            class="m-btn"
            :data-video="slide.video"
            @click="openVideoModal(slide.video)"
          >
            Смотреть полностью
            <img
              loading="lazy"
              src="/v2/main/play.svg"
              alt="play"
              :style="{ backgroundColor: slide.color }"
            >
          </button>
        </div>
      </template>
    </custom-scroll-slider>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import CustomScrollSlider from '~/components/v2/common/CustomScrollSlider.vue';

export default {
  name: 'ParentsVideos',
  components: { CustomScrollSlider },
  data: () => ({
    items: [
      {
        name: 'Мария Эрмель',
        description: 'Мама двух учениц',
        image: '7.webp',
        text: 'Онлайн-школа «Синергия» спасла моей дочери жизнь»',
        video: 'https://www.youtube.com/embed/vNS2eP2ir-o?si=egsl7jzs3CSEgCzx',
        color: '#73B0F4',
      },
      {
        name: 'Арина',
        description: 'Ученица 5 класса',
        image: '8.webp',
        text: '«Мы очень любим путешествовать и, если учиться в обычной школе, у меня бы этого просто не вышло»',
        video: 'https://www.youtube.com/embed/yDtxNwG2x28?si=E3r3bqiaVf7uL-_W',
        color: '#FB836F',
      },
      {
        name: 'Ольга Дё',
        description: 'Мама ученицы 9 класса ',
        image: '9.webp',
        text: '«Синергия» избавила мою дочь от стресса и постоянных антибиотиков»',
        video: 'https://www.youtube.com/embed/XzZLNKMPeOw?si=faDVTbW3uvTzMtJs',
        color: '#B3A4EE',
      },
      {
        name: 'Сергунин Егор',
        description: 'Ученик 11А класса',
        image: '10.webp',
        // eslint-disable-next-line max-len,vue/max-len
        text: '&laquo;Являюсь профессиональным спортсменом по&nbsp;плаванию, сборная Краснодарского края. Учась в&nbsp;обычной школе офлайн, я&nbsp;бы ничего не&nbsp;успевал&raquo;',
        video: '/v2/new-year/2024/feedbacks/feedback-1.mp4',
        color: '#FBD277',
      },
      {
        name: 'Макарова Луиза',
        description: 'Ученица 9Г класса',
        image: '11.webp',
        // eslint-disable-next-line max-len,vue/max-len
        text: '«В прошлой школе меня обижали, ребята не очень ко мне относились. Я очень рада что у меня сейчас такой хороший, дружный класс»',
        video: '/v2/new-year/2024/feedbacks/feedback-4.mp4',
        color: '#FB836F',
      },
      {
        name: 'Варданян Зинаида',
        description: 'Ученица 8А класса',
        image: '12.webp',
        // eslint-disable-next-line max-len,vue/max-len
        text: 'После перехода в онлайн-школу, у меня появилось гораздо больше времени. Я могу не бежать после школы домой, чтобы быстро переодеться и побежать на тренировку»',
        video: '/v2/new-year/2024/feedbacks/feedback-3.mp4',
        color: '#73B0F4',
      },
      {
        name: 'Ольга Бадави',
        description: 'Мама ученика 6Б класса',
        image: '1.webp',
        text: '«Мы путешествуем по миру... посоветовали онлайн‑школу Синергия. Я очень довольна качеством обучения»',
        video: '/v2/videos/Отзыв семьи, путешествующей.mp4',
        color: '#FB836F',
      },
      {
        name: 'Арина',
        description: 'Ученица 9В класса',
        image: '2.webp',
        text: '«Мне очень удобно учиться онлайн так как я занимаюсь профессиональным спортом и часто в разъездах»',
        video: 'https://www.youtube.com/embed/HRkeCuuzz3s?si=6G8WTw2zdi5UjI_A',
        color: '#FBD277',
      },
      {
        name: 'Марина',
        description: 'Мама ученика 7А класса',
        image: '3.webp',
        // eslint-disable-next-line max-len,vue/max-len
        text: '«Мой сын профессиональный спортсмен. Я с большим удовольствием могу сказать, что мы получили очень много плюсов от поступления в онлайн‑школу»',
        video: 'https://www.youtube.com/embed/ROoY-Yv4rPQ?si=9Cuovbwq_9JOBrjE',
        color: '#73B0F4',
      },
      {
        name: 'Галия Симонцева',
        description: 'Мама ученика 5А класса',
        image: '4.webp',
        // eslint-disable-next-line max-len,vue/max-len
        text: '«Там такая замечательная платформа, даже если мой сын пропустил урок, он может самостоятельно посмотреть его и нагнать то, что пропустил»',
        video: 'https://www.youtube.com/embed/6oq0WecI55U?si=JEEfpgMQ8p_IH0FV',
        color: '#B3A4EE',
      },
      {
        name: 'Алла Цароева',
        description: 'Мама ученика 7А класса',
        image: '5.webp',
        // eslint-disable-next-line max-len,vue/max-len
        text: '«Долго искала своему сыну такую школу где будет индивидуальный подход, помогу ему с дальнейшим развитием»',
        video: 'https://www.youtube.com/embed/yhUeEJe_uN0?si=KJjU2uk1kKGLmh4O',
        color: '#F199CE',
      },
      {
        name: 'Александра Козырева',
        description: 'Мама ученицы 7Б класса',
        image: '6.webp',
        // eslint-disable-next-line max-len,vue/max-len
        text: '«За 2 дня нас зачислили дистанционно из Ирландии… через несколько недель дочка рассказала, что начала понимать алгебру и геометрию»',
        video: 'https://www.youtube.com/embed/0oYUcMPA_EQ?si=uismxE5vyXi4s0EF',
        color: '#61DDBC',
      },
    ],
  }),
  computed: {
    ...mapGetters({
      isTablet: 'isTablet',
      isLaptop: 'isLaptop',
    }),
  },
  methods: {
    ...mapActions('modals', {
      openVideoModal: 'openVideo',
    }),
    nextSlide() {
      this.$refs.slider.nextSlide();
    },
    prevSlide() {
      this.$refs.slider.prevSlide();
    },
  },
};
</script>

<style scoped lang="scss">

.parents-videos {
  background-color: $gray-bg;
  padding-top: 175px;
  position: relative;

  @include media-down(laptop) {
    padding-top: 120px;
  }

  @include media-down(tablet) {
    padding-top: 75px;
  }

  .parents-videos--bg-image {
    position: absolute;
    top: -70px;
    left: calc((100% - 1320px) / 2 - 120px);

    @include media-down(desktop) {
      left: -14px;
      width: 100px;
    }

    @include media-down(tablet) {
      width: 70px;
    }
  }

  .parents-videos__head {
    max-width: 1278px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    gap: 58px;
    margin-bottom: 60px;
    padding-left: 20px;
    padding-right: 20px;

    @include media-down(laptop) {
      margin-right: 8px;
      margin-left: 8px;
      padding-left: 0;
      padding-right: 0;
    }

    @include media-down(tablet) {
      margin-bottom: 40px;
    }

    .title-primary {
      letter-spacing: -1.3px;
      max-width: 850px;
      position: relative;
      z-index: 3;

      @include media-down(laptop) {
        font-size: 50px;
      }

      @include media-down(tablet) {
        text-align: center;
        font-size: 40px;
        letter-spacing: -0.6px;
      }

      @include media-down(mobile) {
        font-size: 30px;
      }

      span {
        color: #89C053;
      }

      .parents-videos__head_earth {
        position: absolute;
        width: 150px;
        height: 150px;
        object-fit: contain;
        top: 52px;

        @include media-down(laptop) {
          top: auto;
          width: 100px;
          height: 100px;
        }

        @include media-down(tablet) {
          width: 67px;
          height: 85px;
          right: -4px;
        }
      }
    }

    .parents-videos__controls {
      flex-shrink: 0;
      padding-top: 8px;

      @include media-down(tablet) {
        display: none;
      }

      button {
        transition: 100ms;
        filter: drop-shadow(2px 2px 0 $black);

        &:hover {
          filter: none;
          transform: translate(2px, 2px);
        }

        img {
          background-color: $gray-bg;
          border-radius: 50%;
        }
      }
    }
  }
}

.parents-videos__slides {
  overflow: auto;
  display: flex;
  gap: 50px;
  padding-bottom: 10px;
  cursor: grab;

  @include media-down(laptop) {
    gap: 36px;
  }

  @include media-down(tablet) {
    gap: 22px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.parents-videos__slide {
  width: 390px;
  height: 550px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  position: relative;
  user-select: none;

  @include media-down(tablet) {
    width: 296px;
    height: 400px;
    padding: 8px;
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 10px;
    top: 10px;
    border-radius: 20px;
    border: 2px solid $black;
    z-index: 1;
    background-color: currentColor;

    @include media-down(tablet) {
      left: 8px;
      top: 8px;
      border-radius: 16px;
    }
  }

  .parents-videos__slide_image {
    border-radius: 20px;
    overflow: hidden;
    border: 2px solid $black;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;

    @include media-down(tablet) {
      border-radius: 16px;
    }

    img {
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      position: absolute;
      left: -2px;
      top: -2px;
      pointer-events: none;
    }
  }

  h4 {
    font-size: 30px;
    font-weight: 700;
    line-height: 130%;
    color: $white-color;
    position: relative;
    z-index: 2;

    @include media-down(tablet) {
      font-size: 25px;
      padding-top: 8px;
      padding-left: 8px;
    }
  }

  h5 {
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 18px;
    font-weight: 700;
    line-height: 130%;
    color: $white-color;
    position: relative;
    z-index: 2;

    @include media-down(tablet) {
      font-size: 16px;
      line-height: 100%;
      padding-left: 8px;
    }
  }

  .text-primary {
    margin-top: auto;
    margin-bottom: 18px;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    letter-spacing: -0.2px;
    color: $white-color;
    position: relative;
    z-index: 2;

    @include media-down(tablet) {
      margin-bottom: 24px;
    }
  }

  button {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    gap: 10px;
    color: $white-color;
    margin-left: auto;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 16px;
    font-weight: 700;

    @include media-down(tablet) {
      font-size: 14px;
      gap: 6px;
      padding-right: 8px;
      padding-bottom: 8px;
    }

    img {
      width: 44px;
      height: 44px;
      border-radius: 50%;
      pointer-events: none;

      @include media-down(tablet) {
        width: 32px;
        height: 32px;
      }
    }
  }
}

</style>
